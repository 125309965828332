<template>
  <div style="text-align: center; margin-top: 20vh;">{{ step }}</div>
</template>
<script>
import { login } from "@/api/login";
import { ElMessage } from "element-plus/lib/components"

export default {
  name: "oauth2",
  components: {},
  data() {
    return {
      formData: {
        client_id: "oauth2",
        client_secret: "oauth2.secret",
        grant_type: null,
        code: null
      },
      step: '加载中',
      redirectUrl: null
    };
  },
  methods: {
    loginEvent() {
      let that = this;

      this.step = '钉钉授权登录中…';
      //this.step = JSON.stringify(this.formData)
      login(this.formData).then(
        (res) => {
          ElMessage.success({ message: "授权登录成功,正在跳转中…", type: "success", });
          that.$router.push(that.redirectUrl || "/console");
        },
        (res) => {
          alert('login error: ' + JSON.stringify(res));
          console.log("error", res);
        }
      );
    },
    dingTalkInit() {
      dd.ready(() => {
        this.step = '钉钉账户快捷登录中…';
        // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
        dd.runtime.permission.requestAuthCode({
          corpId: "ding75487f3aadf04d3135c2f4657eb6378f",
          agentId: "2616846705",
          onSuccess: (result) => {
            this.step = '获取钉钉信息成功…';

            /*{
                code: 'hYLK98jkf0m' //string authCode
            }*/
            //alert('dd result: ' + JSON.stringify(result));
            //document.body.innerHTML = JSON.stringify(result);
            this.formData.code = result.code;
            this.loginEvent();
          },
          onFail: (err) => {
            alert('dd error: ' + JSON.stringify(err));
            //document.body.innerHTML = JSON.stringify(err);
          }
        });
      });
    },
  },
  mounted() {
    this.redirectUrl = this.$route.query.redirectUrl;

    //dingtalk_code   钉钉授权模式
    this.formData.grant_type = this.$route.query.grant_type ?? "dingtalk_code";

    if (this.formData.grant_type == 'dingtalk_code') {
      this.step = '检测到钉钉应用环境,钉钉脚本加载中…';
      const script = document.createElement('script')
      script.src = "https://g.alicdn.com/dingding/dingtalk-jsapi/3.0.12/dingtalk.open.js";
      script.onload = () => {
        console.log('dingtalk脚本已加载')

        if (dd.env.platform == 'notInDingTalk') {
          //this.$alert('非钉钉应用环境，即将跳转至登录页面', '提示')
          ElMessage.error("非钉钉应用环境，即将跳转至登录页面…");
          setTimeout(function () {
            location.href = "/";
          }, 2000);
          return;
        }

        // 在这里执行JS代码
        this.dingTalkInit();
      }
      document.head.appendChild(script);
    }

  },
};
</script>
<style scoped></style>